import { Box, Checkbox, FormControlLabel, FormLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DomLayoutType, useSettings } from '@contexts/SettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { domFontSize, domCellPadding } from 'src/data/enumTypeMaps';
import { StyledButton } from '@/components/styledComponents';
import styles from './domHeaderSettings.module.scss';
import { HexColorPicker } from "react-colorful";
import { useDeviceContext } from '@/contexts/DeviceContext';
import { useSymbol } from '@/contexts/SymbolContext';

interface DomHeaderSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
  hideClose?: boolean;
}

const DomHeaderSettings: React.FC<DomHeaderSettingsProps> = ({ onSaved, onCancelled, showButtons, hideClose }) => {
  const settings = useSettings();
  
  const [domHeaderVolProfileHide, setDomHeaderVolProfileHide] = useState<boolean>(false);
  const [domHeaderJoinButtonsHide, setDomHeaderJoinButtonsHide] = useState<boolean>(false);
  const [domHeaderClosePositionButtonHide, setDomHeaderClosePositionButtonHide] = useState<boolean>(false);
  const [domHeaderReversePositionButtonHide, setDomHeaderReversePositionButtonHide] = useState<boolean>(false);
  const [domHeaderCancelOrdersButtonHide, setDomHeaderCancelOrdersButtonHide] = useState<boolean>(false);
  const [domHeaderCancelAllButtonHide, setDomHeaderCancelAllButtonHide] = useState<boolean>(false);
  const [domHeaderFlattenAllButtonHide, setDomHeaderFlattenAllButtonHide] = useState<boolean>(false);
  const [domHeaderHidePNLColumn, setDomHeaderHidePNLColumn] = useState<boolean>(false);
  const [domLayoutClassic, setDomLayoutClassic] = useState<boolean>(false);
  const [domHeaderShowMyBidAlways, setDomHeaderShowMyBidAlways] = useState<boolean>(false);
  const [domHeaderShowMyAskAlways, setDomHeaderShowMyAskAlways] = useState<boolean>(false);
  const [domSize, setDomSize] = useState<domFontSize>(settings.customSettings.domFontSize);
  const [domCell, setDomCell] = useState<domCellPadding>(settings.customSettings.domCellPadding);
  const [domBidBGColorFriendly, setDomBidBGColorFriendly] = useState<string>(settings.customSettings?.domBidBGColorFriendly ?? '#2d8337');
  const [domAskBGColorFriendly, setDomAskBGColorFriendly] = useState<string>(settings.customSettings?.domAskBGColorFriendly ?? '#9f2b27');
  const [showDomBidBGColorPicker, setShowDomBidBGColorPicker] = useState<boolean>(false);
  const [showDomAskBGColorPicker, setShowDomAskBGColorPicker] = useState<boolean>(false);
  const [hideNonFavorites, setHideNonFavorites] = useState<boolean>(settings.customSettings?.hideNonFavorites);
  const { isMobile } = useDeviceContext();
  const [isLoading, setIsLoading] = useState(false);

  const [risk, setRisk] = useState<number | null>(0);
  const [toMake, setToMake] = useState<number | null>(0);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [autoApply, setAutoApply] = useState<boolean>(false);
  const [soundNotifications, setSoundNotifications] = useState<boolean>(true);

  const favorites = useMemo(() => {
    const favs = settings.customSettings.favoriteContracts || [];
    return new Map(favs.map((x) => [x, true]));
  }, [settings.customSettings.favoriteContracts]);

  const updateDomFontSize = useCallback((input: string) => {
    setDomSize(parseInt(input) as domFontSize);
  }, []);

  const updateDomCellPadding = useCallback((input: string) => {
    setDomCell(parseInt(input) as domCellPadding);
  }, []);

  const updateDomBidBGColor = (color: any) => {
    setDomBidBGColorFriendly(color);
  };

  const updateDomAskBGColor = (color: any) => {
    setDomAskBGColorFriendly(color);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setRisk(settings.risk);
    setToMake(settings.toMake);
    setConfirmation(settings.showConfirmations);
    setAutoApply(settings.autoApply);
    setSoundNotifications(settings.soundNotifications);
    setDomHeaderVolProfileHide(settings.customSettings.domHeaderVolProfileHide);
    setDomHeaderJoinButtonsHide(settings.customSettings.domHeaderJoinButtonHide);
    setDomHeaderClosePositionButtonHide(settings.customSettings.domHeaderClosePositionButtonHide);
    setDomHeaderReversePositionButtonHide(settings.customSettings.domHeaderReversePositionButtonHide);
    setDomHeaderCancelOrdersButtonHide(settings.customSettings.domHeaderCancelOrdersButtonHide);
    setDomHeaderCancelAllButtonHide(settings.customSettings.domHeaderCancelAllButtonHide);
    setDomHeaderFlattenAllButtonHide(settings.customSettings.domHeaderFlattenAllButtonHide);
    setDomLayoutClassic(settings.customSettings.domLayoutType === DomLayoutType.Classic);
    setDomHeaderShowMyBidAlways(settings.customSettings.domHeaderShowMyBidAlways);
    setDomHeaderShowMyAskAlways(settings.customSettings.domHeaderShowMyAskAlways);
    setDomHeaderHidePNLColumn(settings.customSettings.domHeaderHidePNLColumn);
    setDomCell(settings.customSettings.domCellPadding);
    setDomBidBGColorFriendly(settings.customSettings?.domBidBGColorFriendly ?? '#2d8337');
    setDomAskBGColorFriendly(settings.customSettings?.domAskBGColorFriendly ?? '#9f2b27');
    setHideNonFavorites(settings.customSettings?.hideNonFavorites ?? false);
  }, [settings]);

  const cancel = useCallback(() => {
    setDomHeaderVolProfileHide(settings.customSettings.domHeaderVolProfileHide);
    setDomHeaderJoinButtonsHide(settings.customSettings.domHeaderJoinButtonHide);
    setDomHeaderClosePositionButtonHide(settings.customSettings.domHeaderClosePositionButtonHide);
    setDomHeaderReversePositionButtonHide(settings.customSettings.domHeaderReversePositionButtonHide);
    setDomHeaderCancelOrdersButtonHide(settings.customSettings.domHeaderCancelOrdersButtonHide);
    setDomHeaderCancelAllButtonHide(settings.customSettings.domHeaderCancelAllButtonHide);
    setDomHeaderFlattenAllButtonHide(settings.customSettings.domHeaderFlattenAllButtonHide);
    setDomLayoutClassic(settings.customSettings.domLayoutType === DomLayoutType.Classic);
    setDomHeaderShowMyBidAlways(settings.customSettings.domHeaderShowMyBidAlways);
    setDomHeaderShowMyAskAlways(settings.customSettings.domHeaderShowMyAskAlways);
    setDomSize(settings.customSettings.domFontSize);
    setDomHeaderHidePNLColumn(settings.customSettings.domHeaderHidePNLColumn);
    setDomCell(settings.customSettings.domCellPadding);
    setDomBidBGColorFriendly(settings.customSettings?.domBidBGColorFriendly ?? '#2d8337');
    setDomAskBGColorFriendly(settings.customSettings?.domAskBGColorFriendly ?? '#9f2b27');
    setHideNonFavorites(settings.customSettings?.hideNonFavorites ?? false);
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {
    settings.customSettings.domHeaderVolProfileHide = domHeaderVolProfileHide;
    settings.customSettings.domHeaderJoinButtonHide = domHeaderJoinButtonsHide;
    settings.customSettings.domHeaderClosePositionButtonHide = domHeaderClosePositionButtonHide;
    settings.customSettings.domHeaderReversePositionButtonHide = domHeaderReversePositionButtonHide;
    settings.customSettings.domHeaderCancelOrdersButtonHide = domHeaderCancelOrdersButtonHide;
    settings.customSettings.domHeaderCancelAllButtonHide = domHeaderCancelAllButtonHide;
    settings.customSettings.domHeaderFlattenAllButtonHide = domHeaderFlattenAllButtonHide;
    settings.customSettings.domLayoutType = domLayoutClassic ? DomLayoutType.Classic : DomLayoutType.Modern;
    settings.customSettings.domHeaderShowMyBidAlways = domHeaderShowMyBidAlways;
    settings.customSettings.domHeaderShowMyAskAlways = domHeaderShowMyAskAlways;
    settings.customSettings.domFontSize = domSize;
    settings.customSettings.domHeaderHidePNLColumn = domHeaderHidePNLColumn;
    settings.customSettings.domCellPadding = domCell;
    settings.customSettings.domBidBGColorFriendly = domBidBGColorFriendly;
    settings.customSettings.domAskBGColorFriendly = domAskBGColorFriendly;
    settings.customSettings.hideNonFavorites = hideNonFavorites;

    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)]).then(() => {
      setIsLoading(false);
      onSaved && onSaved();
    });
  }, [
    settings.customSettings,
    domHeaderVolProfileHide,
    domHeaderJoinButtonsHide,
    domHeaderClosePositionButtonHide,
    domHeaderReversePositionButtonHide,
    domHeaderCancelOrdersButtonHide,
    domHeaderCancelAllButtonHide,
    domHeaderFlattenAllButtonHide,
    domHeaderShowMyBidAlways,
    domHeaderShowMyAskAlways,
    domLayoutClassic,
    domHeaderHidePNLColumn,
    domCell,
    domSize,
    domBidBGColorFriendly,
    domAskBGColorFriendly,
    hideNonFavorites,
    settings,
    settings.saveCustomSettings
  ]);

  const isChanged = useMemo(() => {
    return (
      domHeaderVolProfileHide !== settings.customSettings.domHeaderVolProfileHide ||
      domHeaderJoinButtonsHide !== settings.customSettings.domHeaderJoinButtonHide ||
      domHeaderClosePositionButtonHide !== settings.customSettings.domHeaderClosePositionButtonHide ||
      domHeaderReversePositionButtonHide !== settings.customSettings.domHeaderReversePositionButtonHide ||
      domHeaderCancelOrdersButtonHide !== settings.customSettings.domHeaderCancelOrdersButtonHide ||
      domHeaderCancelAllButtonHide !== settings.customSettings.domHeaderCancelAllButtonHide ||
      domHeaderFlattenAllButtonHide !== settings.customSettings.domHeaderFlattenAllButtonHide ||
      domLayoutClassic !== (settings.customSettings.domLayoutType === DomLayoutType.Classic) ||
      domHeaderShowMyBidAlways !== settings.customSettings.domHeaderShowMyBidAlways ||
      domHeaderShowMyAskAlways !== settings.customSettings.domHeaderShowMyAskAlways ||
      domSize !== settings.customSettings.domFontSize ||
      domHeaderHidePNLColumn !== settings.customSettings.domHeaderHidePNLColumn ||
      domCell !== settings.customSettings.domCellPadding ||
      domBidBGColorFriendly != settings.customSettings.domBidBGColorFriendly ||
      domAskBGColorFriendly != settings.customSettings.domAskBGColorFriendly || 
      hideNonFavorites !== settings.customSettings.hideNonFavorites
    );
  }, [
    settings,
    domHeaderVolProfileHide,
    domHeaderJoinButtonsHide,
    domHeaderClosePositionButtonHide,
    domHeaderReversePositionButtonHide,
    domHeaderCancelOrdersButtonHide,
    domHeaderCancelAllButtonHide,
    domHeaderFlattenAllButtonHide,
    domLayoutClassic,
    domSize,
    settings.customSettings,
    domHeaderShowMyBidAlways,
    domHeaderShowMyAskAlways,
    domHeaderHidePNLColumn,
    domCell,
    domBidBGColorFriendly,
    domAskBGColorFriendly,
    hideNonFavorites
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        maxHeight: '100%',
        margin: '1em'
      }}
      className={isMobile && styles.mobileModal}
    >
      <Box sx={{ textAlign: 'center', paddingBottom: '1.5em' }}>
        <FormLabel sx={{ fontSize: '1.35em' }}>
          DOM / Order Card Settings
          <HelpMessage message='Configure Layout / Settings for DOM & Order Card' />
        </FormLabel>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1em',
          paddingLeft: '1em'
        }}
      >
        <FormControlLabel sx={{ flex: 1 }} control={<Checkbox checked={domLayoutClassic} onChange={() => setDomLayoutClassic(!domLayoutClassic)} />} label='Use Fixed Width Bid / Ask Volumes' />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderVolProfileHide} onChange={() => setDomHeaderVolProfileHide(!domHeaderVolProfileHide)} />}
          label='Hide Vol. Profile Column'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderJoinButtonsHide} onChange={() => setDomHeaderJoinButtonsHide(!domHeaderJoinButtonsHide)} />}
          label='Hide Join Bid / Ask Buttons'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderClosePositionButtonHide} onChange={() => setDomHeaderClosePositionButtonHide(!domHeaderClosePositionButtonHide)} />}
          label='Hide Close Position Button'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderReversePositionButtonHide} onChange={() => setDomHeaderReversePositionButtonHide(!domHeaderReversePositionButtonHide)} />}
          label='Hide Reverse Position Button'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderCancelOrdersButtonHide} onChange={() => setDomHeaderCancelOrdersButtonHide(!domHeaderCancelOrdersButtonHide)} />}
          label='Hide Cancel Order Button'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderFlattenAllButtonHide} onChange={() => setDomHeaderFlattenAllButtonHide(!domHeaderFlattenAllButtonHide)} />}
          label='Hide Flatten All Button'
        />

        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderCancelAllButtonHide} onChange={() => setDomHeaderCancelAllButtonHide(!domHeaderCancelAllButtonHide)} />}
          label='Hide Cancel All Button'
        />
        <FormControlLabel sx={{ flex: 1 }} control={<Checkbox checked={domHeaderHidePNLColumn} onChange={() => setDomHeaderHidePNLColumn(!domHeaderHidePNLColumn)} />} label='Hide P&L Column' />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderShowMyBidAlways} onChange={() => setDomHeaderShowMyBidAlways(!domHeaderShowMyBidAlways)} />}
          label='Show My Bid Always'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={<Checkbox checked={domHeaderShowMyAskAlways} onChange={() => setDomHeaderShowMyAskAlways(!domHeaderShowMyAskAlways)} />}
          label='Show My Ask Always'
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          disabled={!favorites || favorites.size === 0}
          control={<Checkbox checked={!(!favorites || favorites.size === 0) && hideNonFavorites} onChange={() => setHideNonFavorites(!hideNonFavorites)} />}
          label={!favorites || favorites.size === 0 ? 'Hide Non-Favorites (Add favorites to enable)' : 'Hide Non-Favorites'}
        />
      </Box>

      <Box sx={{ paddingTop: '2em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <FormLabel sx={{color: 'white'}}>DOM Bid BG Color</FormLabel>
          <Box sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: domBidBGColorFriendly }} onClick={() => setShowDomBidBGColorPicker((prev) => !prev)} />
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel sx={{color: 'white'}}>DOM Ask BG Color</FormLabel>
          <Box sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: domAskBGColorFriendly }} onClick={() => setShowDomAskBGColorPicker((prev) => !prev)} />
        </Box>
      </Box>

      <Box sx={{ paddingTop: '2em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <FormLabel>Font Size</FormLabel>
          <Select variant='outlined' type='number' value={domSize === undefined ? domFontSize.Small : domSize} style={{ margin: '1em 0' }} onChange={(t) => updateDomFontSize(t.target.value + '')}>
            <MenuItem value={domFontSize.Small} key={domFontSize.Small}>
              Compact
            </MenuItem>
            <MenuItem value={domFontSize.Medium} key={domFontSize.Medium}>
              Medium
            </MenuItem>
            <MenuItem value={domFontSize.Large} key={domFontSize.Large}>
              Large
            </MenuItem>
          </Select>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <FormLabel>Dom Cell Padding</FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={domCell === undefined ? domCellPadding.Small : domCell}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateDomCellPadding(t.target.value + '')}
          >
            <MenuItem value={domCellPadding.Small} key={domCellPadding.Small}>
              Compact
            </MenuItem>
            <MenuItem value={domCellPadding.Large} key={domCellPadding.Large}>
              Large
            </MenuItem>
            <MenuItem value={domCellPadding.XLarge} key={domCellPadding.XLarge}>
              XLarge
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {!hideClose ? (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} sx={{ margin: '1em', marginBottom: 0 }}>
            {isChanged ? 'Cancel' : 'Close'}
          </StyledButton>
        ) : (
          <>
            {isChanged && (
              <StyledButton color='error' disabled={isLoading} onClick={cancel} sx={{ margin: '1em', marginBottom: 0 }}>
                Cancel
              </StyledButton>
            )}
          </>
        )}

        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} sx={{ margin: '1em', marginBottom: 0 }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>

      {showDomBidBGColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowDomBidBGColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={domBidBGColorFriendly} onChange={updateDomBidBGColor} />
          </div>
        </div>
      )}
      {showDomAskBGColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowDomAskBGColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={domAskBGColorFriendly} onChange={updateDomAskBGColor} />
          </div>
        </div>
      )}
    </Box>
  );
};

export default DomHeaderSettings;
