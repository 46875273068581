import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/sass/theme.scss';
import { defaultColor, isDarkSwitchActive, isMultiColorActive, themeColorStorageKey } from './constants/defaultValues';
import config, { TradingPlatforms, loadConfig } from './config';
import * as Sentry from '@sentry/react';

const color = (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey) ? localStorage.getItem(themeColorStorageKey) : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

loadConfig().then((cfg) => {
  try {
    const APP_ID = cfg.intercomId;
    if (APP_ID) {
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + APP_ID;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();

      window.Intercom('boot', {
        app_id: APP_ID,
        hide_default_launcher: true
        //Website visitor so may not have any user related info
      });
    }
  } catch (e) {
    console.error('Unable to load intercom', e);
  }

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://b4ae83b218d4480397b461b127490c47@o152829.ingest.sentry.io/4505284847337472',
      environment: cfg.env,
      release: config.version,
      tracesSampleRate: 1.0,
      enableTracing: true
      // integrations: [
      //   new Sentry.BrowserTracing({
      //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      //   }),
      //   new Sentry.Replay(),
      // ],
      // // Performance Monitoring
      // tracesSampleRate: 1.0, // Capture 100% of the transactions
      // // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    Sentry.addTracingExtensions();
  }
  let render = () => {
    require('./AppRenderer');
  };
  render();
});
