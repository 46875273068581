import { useApi } from '@contexts/ApiContext';
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { IUserSettingsModel, JsonDocument, PersonalDailyAction, PersonalDailyLimitsRequest, RootElement, TradingAccountType } from '@api/userApi';
import { networkSpeed, chartPlotSide, topNavTextSize } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import styles from './settings.module.scss';
import { StyledButton } from '@/components/styledComponents';

interface MiscSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const MiscSettings: React.FC<MiscSettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const { tradingAccountApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();

  const [supressAlerts, setSupressAlerts] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [soundNotifications, setSoundNotifications] = useState<boolean>(true);
  const [topNavText, setTopNavText] = useState<topNavTextSize>(settings.customSettings.topNavTextSize);

  useEffect(() => {
    setSoundNotifications(settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts);
    setSupressAlerts(settings.customSettings.supressAlerts);
    setTopNavText(settings.customSettings.topNavTextSize);
  }, [settings]);

  const cancel = useCallback(() => {
    setSoundNotifications(settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts);
    setSupressAlerts(settings.customSettings.supressAlerts);
    setTopNavText(settings.customSettings.topNavTextSize);
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {
    settings.customSettings.supressAlerts = supressAlerts;
    settings.customSettings.topNavTextSize = topNavText;
    settings.customSettings.audioAlerts = soundNotifications;

    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)])
      .then(() => {
        setIsLoading(false);
        onSaved && onSaved();
        toast('Settings saved', { type: 'success', hideProgressBar: true });
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });
  }, [activeTradingAccount.accountId, soundNotifications, supressAlerts, topNavText, settings.saveCustomSettings]);

  const updateTopNavText = useCallback((input: string) => {
    setTopNavText(parseInt(input) as topNavTextSize);
  }, []);

  const isChanged = useMemo(() => {
    const currentSoundSetting = settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts;
    return soundNotifications !== currentSoundSetting || supressAlerts !== settings.customSettings.supressAlerts || topNavText !== settings.customSettings.topNavTextSize;
  }, [
    soundNotifications,
    supressAlerts,
    settings,
    settings.customSettings,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    topNavText
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        maxHeight: '100%'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Alerts
            <HelpMessage message='Alerts for Orders, Trades, Closures, etc' />
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={soundNotifications == null || soundNotifications == undefined ? true : soundNotifications}
                onChange={() => setSoundNotifications(soundNotifications == null || soundNotifications == undefined ? false : !soundNotifications)}
              />
            }
            label='Enable Sound Alerts'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supressAlerts == null || supressAlerts == undefined ? true : supressAlerts !== true}
                onChange={() => setSupressAlerts(supressAlerts == null || supressAlerts == undefined ? true : !supressAlerts)}
              />
            }
            label='Enable Visual Alerts'
          />
        </FormGroup>

        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Balances Font Size
            <HelpMessage message='The font size for the balances area (BAL, MLL, RP&L, etc)' />
          </FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={topNavText === undefined ? topNavTextSize.Regular : topNavText}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateTopNavText(t.target.value + '')}
          >
            <MenuItem value={topNavTextSize.Regular} key={topNavTextSize.Regular}>
              Regular
            </MenuItem>
            <MenuItem value={topNavTextSize.Large} key={topNavTextSize.Large}>
              Medium
            </MenuItem>
            <MenuItem value={topNavTextSize.XLarge} key={topNavTextSize.XLarge}>
              Large
            </MenuItem>
          </Select>
        </FormGroup>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons) && (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} style={{ margin: '1em' }}>
            Cancel
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} style={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default MiscSettings;
