import React, { Suspense, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import DragDropProvider from './contexts/DragDropContext';
import ModalContextProvider from './contexts/ModalContext';
import TraderView from './views/trader/traderView';
import AuthContextProvider from './contexts/AuthContext';
import { NavRoutes } from './data/NavRoutes';
import ResourcesView from './views/resources/resourcesView';
import ApiContextProvider from './contexts/ApiContext';
import SettingsView from './views/settings/settingsView';
import SupportView from './views/support/supportView';
import StatsView from './views/stats/statsView';
import { ThemeProvider } from './contexts/ThemeContext';
import ForgotPasswordView from './views/user/forgot-password';
import ResetPasswordView from './views/user/reset-password';
import UserLayout from './layout/UserLayout';
import LoginLayout from './layout/LoginLayout';
import MobileLayout from './layout/MobileLayout';
import MobileChart from '@views/mobileChart/mobileChart';
import Loading from '@components/Loading';
import { ToastContainer } from 'react-toastify';
import AgreementsView from '@views/agreements/agreementsView';
import { Box } from '@mui/material';
import Panel from '@components/topstep/panel';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { TradingPlatforms, config } from '@/config';
import { Helmet } from 'react-helmet';
import MobileHome from './views/mobile/mobileHome';
const ViewError = React.lazy(() => import('./views/error'));
const ViewLogin = React.lazy(() => import('./views/user/login'));
import Dom from './views/trader/components/dom/dom';
import Ordercard from './views/trader/components/ordercard/ordercard';
import Chart from './views/trader/components/charts/chart';
import Positions from './views/trader/components/positions/positions';
import Trades from './views/trader/components/trades/trades';
import DomSettingsModal from './views/trader/components/dom/domSettingsModal';
import DomHeaderSettings from './components/domHeaderSettings';
import RiskSettings from './components/topstep/riskSettings';
import CopyTrading from './components/copyTrading/copyTrading';
import MiscSettings from './components/topstep/miscSettings';
import Orders from './views/trader/components/orders/orders';
import ChartSettings from './components/topstep/chartSettings';
import Tilt from './views/trader/components/tilt/tilt';
import Quotes from './views/trader/components/quoteBoard/quotes';
import TimeSales from './views/trader/components/timesales/sales';
import MobileAccountInfo from './views/mobile/mobileAccountInfo';
import Accounts from './views/trader/components/accounts/accounts';
import NoMobile from './components/topstep/noMobile';
import PerformanceView from './views/performance/performanceView';
import { useDeviceContext } from '@/contexts/DeviceContext';
import SharedLayout from './layout/SharedLayout';
import AutoLogout from './views/trader/components/autoLogout';
import KeyboardListener from './components/topstep/keyboardListener';
import { VisibilityProvider } from './contexts/VisibilityContext';

LicenseInfo.setLicenseKey('7f57bfb3cbb9276574569889e2e614ebTz04NTczNixFPTE3NDEyMTk1NjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const mobileTabData = {} as any;

const App: React.FC = (): JSX.Element => {
  const { isMobile } = useDeviceContext();

  const platformRoutes = useMemo(() => {
    switch (config.platform) {
      case TradingPlatforms.TopstepX:
      case TradingPlatforms.AlphaFutures:
        return [<Route key='tsx-support' path={NavRoutes.Support} element={<SupportView />} />];
      case TradingPlatforms.E8:
        return [<Route key='e8-support' path={NavRoutes.Support} element={<SupportView />} />];
      default:
        return [];
    }
  }, [config.platform]);

  const desktopLayout = useMemo(() => {
    return (
      <Route path='/' element={<UserLayout />}>
        <Route path={'/'} element={<TraderView />} />
        <Route path={NavRoutes.Trade} element={<TraderView />} />
        <Route path={NavRoutes.Resources} element={<ResourcesView />} />
        <Route path={NavRoutes.Settings} element={<SettingsView />} />
        <Route path={NavRoutes.Stats} element={<PerformanceView />} />
        {platformRoutes}
      </Route>
    );
  }, [platformRoutes]);

  const mobileLayout = useMemo(() => {
    return (
      <Route path='/' element={<MobileLayout />}>
        <Route path='/' element={<MobileHome />} />
        <Route path={NavRoutes.MobileChart} element={<Chart mobile={true} tabData={mobileTabData} />} />
        <Route path={NavRoutes.MobileTrading}>
          <Route path={NavRoutes.MobileDom} element={<Dom showLinkedOrders={() => {}} changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobileOrder} element={<Ordercard changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobileTilt} element={<Tilt />} />
          <Route path={NavRoutes.MobileQuotes} element={<Quotes changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobileTimeAndSales} element={<TimeSales changed={() => {}} tabData={mobileTabData} />} />
        </Route>
        <Route path={NavRoutes.MobileAccount}>
          <Route path={NavRoutes.MobileAccountInfo} element={<MobileAccountInfo />} />
          <Route path={NavRoutes.MobileAccounts} element={<Accounts changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobilePositions} element={<Positions changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobileOrders} element={<Orders changed={() => {}} tabData={mobileTabData} />} />
          <Route path={NavRoutes.MobileTrades} element={<Trades changed={() => {}} tabData={mobileTabData} />} />
        </Route>
        <Route path={NavRoutes.MobileSettings}>
          <Route path={NavRoutes.MobileDomSettings} element={<DomHeaderSettings hideClose />} />
          <Route path={NavRoutes.MobileChartSettings} element={<ChartSettings />} />
          <Route path={NavRoutes.MobileRiskSettings} element={<RiskSettings />} />
          <Route path={NavRoutes.MobileCopyTrading} element={<CopyTrading />} />
          <Route path={NavRoutes.MobileMiscSettings} element={<MiscSettings />} />
        </Route>

        <Route path={NavRoutes.MobileCharts} element={<MobileChart />} />
      </Route>
    );
  }, [isMobile, config.features.mobile]);

  const sharedLayout = useMemo(() => {
    return (
      <Route path={NavRoutes.Share} element={<SharedLayout />}>
        <Route path={NavRoutes.SharedStats} element={<PerformanceView />} />
      </Route>
    );
  }, []);

  const getLayout = () => {
    // If url starts with /shared, use shared layout
    if (window.location.pathname.startsWith('/share')) {
      return sharedLayout;
    }

    return isMobile ? mobileLayout : desktopLayout;
  };

  const routes = useMemo(() => {
    return (
      <>
        {getLayout()}

        <Route element={<LoginLayout />}>
          <Route path={NavRoutes.Login} element={<ViewLogin />} />
          <Route path={NavRoutes.ForgotPassword} element={<ForgotPasswordView />} />
          <Route path={NavRoutes.ResetPassword} element={<ResetPasswordView />} />
          <Route path={NavRoutes.Agreements} element={<AgreementsView />} />
        </Route>
        {isMobile ? <Route path='*' element={<MobileHome />} /> : <Route path='*' element={<ViewError />} />}
      </>
    );
  }, [platformRoutes, mobileLayout, desktopLayout]);

  return (
    <div className='h-100'>
      <Helmet>
        <title>{config.platformName}</title>
      </Helmet>
      <ThemeProvider>
        <ModalContextProvider>
          <DragDropProvider>
            <ApiContextProvider>
              <AuthContextProvider>
                <IntlProvider locale='en' defaultLocale='en'>
                  <VisibilityProvider>
                    <React.Fragment>
                      <NotificationContainer />
                      <Suspense fallback={<Loading />}>
                        <Routes>{routes}</Routes>
                      </Suspense>
                      <ToastContainer
                        toastStyle={{ background: isMobile ? '#1c1e23eb' : '', borderBottom: isMobile ? '1px solid #000' : '' }}
                        autoClose={isMobile ? 2000 : 3000}
                        theme='dark'
                        position={isMobile ? 'top-center' : 'bottom-left'}
                        stacked={isMobile ? false : true}
                        limit={isMobile ? 3 : undefined}
                      />
                      <AutoLogout />
                    </React.Fragment>
                  </VisibilityProvider>
                </IntlProvider>
              </AuthContextProvider>
            </ApiContextProvider>
          </DragDropProvider>
        </ModalContextProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
