import { config } from '@/config';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DefaultTsModal, TsModal } from './modal';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button, { ButtonType } from '@/components/topstep/button';

const AutoUpdate = () => {
  const localVersion = config.versionFromFile;
  const [remoteVersion, setRemoteVersion] = useState(localVersion);

  const [modalShown, setModalShown] = useState(localVersion !== remoteVersion);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const onPostpone = useCallback(() => {
    setModalShown(false);
    setAutoRefresh(false);
    setRemoteVersion(localVersion);
    toast.dismiss('updateToast');
  }, [localVersion]);

  useEffect(() => {
    if (autoRefresh) {
      const timeout = setTimeout(() => {
        window.location.reload();
      }, 3600000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoRefresh]);

  const onModalAccept = () => {
    window.location.reload();
  };

  useEffect(() => {
    // Set up to repeat every 5 minutes
    setInterval(() => {
      fetch('/version.json').then((result) => {
        result.json().then((dataJson) => {
          setRemoteVersion(dataJson.version);
        });
      }).catch(() => {
        console.error('Failed to fetch version.json');
      });
    }, 300000);
  }, []);

  useEffect(() => {
    if (localVersion !== remoteVersion) {
      setModalShown(true);
      setAutoRefresh(true);

      toast.info(
        <div>
          <p style={{ margin: 2 }}>A new version is available, please refresh your browser.</p>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button buttonType={ButtonType.green} onClick={onModalAccept} style={{ padding: 5 }}>
              Refresh Now
            </Button>
            <Button buttonType={ButtonType.light} onClick={onPostpone} style={{ padding: 5 }}>
              I'll do it later
            </Button>
          </Box>
        </div>,
        {
          autoClose: false,
          closeButton: false,
          toastId: 'updateToast',
          icon: false
        }
      );
    }
  }, [remoteVersion]);

  return null;
};

export default AutoUpdate;
